import {useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {Check, ContentCopy} from "@mui/icons-material";

type ClipboardTextType = {
    text: string,
}

export function ClipboardText({ text }: ClipboardTextType) {
    const [clicked, setClicked] = useState(false);

    function copy() {
        setClicked(true);
        navigator.clipboard.writeText(text).then();
        setTimeout(() => setClicked(false), 2000);
    }

    return <Grid onClick={copy} container direction='row' justifyContent='space-between' >
        <Grid item>
            <Typography>{text}</Typography>
        </Grid>
        <Grid item><Box sx={{width: '10px'}}/></Grid>
        <Grid>
            {clicked ? <Check color='secondary'/> : <ContentCopy/>}
        </Grid>
    </Grid>
}
