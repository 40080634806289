import {Box, Grid, IconButton, Typography} from "@mui/material";
import {Project} from "../domain/project";
import {useTranslation} from "react-i18next";
import {Add, GitHub, OpenInNew, Remove} from "@mui/icons-material";
import {ProjectCardIcon} from "./ProjectCardIcon";
import {useState} from "react";


type ProjectCardType = {
    project: Project;
}

export function ProjectCard({project}: ProjectCardType) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);

    function toggle() {
        setOpen(!open);
    }

    return <Box sx={{width: '100%', height: '100%', margin: '2px'}}>
        <Grid sx={{marginBottom: '10px'}}>
            <Typography color='secondary'>{project.fields.join(' | ')}</Typography>
        </Grid>

        <Grid
            container
            justifyContent='space-between'
            direction='row'>
            <Grid item xs={8}>
                    <Typography sx={{display: 'inline'}} variant='h5'>{t(`project.${project.id}.name`)}&nbsp;</Typography>
            </Grid>

            <Grid item xs={4} sx={{textAlign: 'right'}}>
                    <IconButton color='secondary' sx={{display: 'inline', marginLeft: '-5px'}} onClick={toggle}>{open ? <Remove/> : <Add/>}</IconButton>
                    <ProjectCardIcon disabled={project.isPrivate} url={project.link}>
                        <OpenInNew/>
                    </ProjectCardIcon>
                    <ProjectCardIcon disabled={project.isCloseSource} url={project.code}>
                        <GitHub/>
                    </ProjectCardIcon>
            </Grid>
        </Grid>

        {open && <img width='100%' src={project.image} alt={project.id}/>}

        {open && project.stacks.map(stack => <img width='30px' style={{margin: '5px'}} alt={stack} src={`/img/stack/${stack}.png`}/>)}


        <Typography sx={{color: 'text.secondary', textAlign: 'justify'}}>
            {t(`project.${project.id}.description`)}
        </Typography>

    </Box>
}
