import {useContext, useEffect, useState} from "react";
import {Box, Grid, Typography, useTheme} from "@mui/material";
import {range} from "../utils";
import {Tile} from "./Tile";
import {NAVBAR_HEIGHT} from "./Navbar";
import {ArrowDownward} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {ServiceContext} from "../App";

export function Paving() {
    const theme = useTheme();
    const { t } = useTranslation();
    const svc = useContext(ServiceContext);

    const [columns, setColumns] = useState(0);
    const [rows, setRows] = useState(0);
    const [size, setSize] = useState(0);

    function resize() {
        const w = window.innerWidth;
        const columns = svc.device.isMobile() ? 6 : 12;

        const tileWidth = w / columns;
        setSize(tileWidth);
        setRows(Math.floor(window.innerHeight / tileWidth));
        setColumns(columns);

    }

    useEffect(() => {
        resize();
        window.addEventListener('resize', () => {
            if (svc.device.isMobile()) {
                return; // on mobile screen expand when scrolling down avoid to resize
            }
            resize();
        });
        return () => window.removeEventListener('resize', resize);
    })

    function addLine(line: string): JSX.Element {
        return <>
            <Grid item xs={2} md={1}><Box sx={{height: size}}/></Grid>
            <Grid item xs={8} md={3} sx={{
                height: size,
                display: 'flex',
                alignItems: 'center',    // Vertically center
                background: theme.palette.background.default
            }}>
                <Typography color='primary' variant='h4'>{line}</Typography>
            </Grid>
            <Grid item xs={2} md={8}/>
        </>
    }

    function displayTitle(): JSX.Element {
        return <Grid
            sx={{
                position: 'absolute',
                left: 0,
                width: '100%'
            }}
            container
            direction="row">
            <Grid item xs={12}><Box sx={{height: size}}/></Grid>
            {addLine(t('common.start'))}
            {addLine(t('common.subtitle1'))}
            {addLine(t('common.subtitle2'))}
        </Grid>
    }

    function displayPaving(): JSX.Element {
        return <Grid
            container
            alignItems='center'
            direction="row">
            {range(columns * (rows - 2 )).map(i => <Grid key={i} item xs={2} md={1}><Tile/></Grid>)}
            {range(columns).map(i => <Grid key={i} item xs={2} md={1}><Tile icon={<ArrowDownward color="secondary"/>}/></Grid>)}
        </Grid>
    }


    return <Box>
        <Box sx={{height: NAVBAR_HEIGHT}}/>
        {displayTitle()}
        {displayPaving()}
    </Box>
}
