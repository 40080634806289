import React, {createContext, useState} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Box, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Home} from "./pages/Home";
import {Navbar} from "./components/Navbar";
import {ProjectsService} from "./services/projectsService";
import {DeviceService} from "./services/deviceService";
import {Footer} from "./components/Footer";
import {ContactsService} from "./services/ContactsService";
import {ModalContacts} from "./components/ModalContacts";

class Services {
    constructor(public project: ProjectsService, public device: DeviceService, public contacts: ContactsService) {
    }
}

const services = new Services(new ProjectsService(), new DeviceService(), new ContactsService());

export const ServiceContext = createContext(services);

const lightTheme = {
    primary: {main: "#000000"},
    secondary: {main: "#d30000"},
    background: {default: "#F4F4F4", paper: "#FFFFFF"},
};

const darkTheme = {
    primary: {main: "#5ec518"},
    secondary: {main: "#337CB5", light: "#CEE0ED"},
    background: {default: "#2f2f2f", paper: "#000000"},
};

function App() {
    const [light, setLight] = useState(true);

    const theme = createTheme({
        typography: {
            fontFamily: `'JetBrains Mono', monospace`, // Set JetBrains Mono as the default font
            body1: {
                fontFamily: '',
            },
            body2: {
                fontFamily: '',
            },
            h4: {
                fontWeight: 700, // Set h4 to bold
            },
        },
        palette: {
            mode: light ? 'light' : 'dark',
            ...(light ? lightTheme : darkTheme),
        }
    });

    return (
        <ServiceContext.Provider value={services}>
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <Box sx={{height: "100%"}}>
                        <ModalContacts/>
                        <Box sx={{display: "flex"}}>
                            <CssBaseline/>
                            <Navbar light={light} onThemeChanged={(value) => setLight(value)}/>
                        </Box>
                        <Box component="main" sx={{display: "flex", height: "100%"}}>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                            </Routes>
                        </Box>
                        <Footer/>
                    </Box>
                </ThemeProvider>
            </BrowserRouter>
        </ServiceContext.Provider>
    );
}

    export default App;
