export enum Stack {
    android= 'android',
    angular= 'angular',
    arduino= 'arduino',
    bitcoin= 'bitcoin',
    data= 'data',
    docker= 'docker',
    electronic= 'electronic',
    ethereum= 'ethereum',
    flask= 'flask',
    flutter= 'flutter',
    fritzing= 'fritzing',
    fusion360= 'fusion360',
    gcp= 'gcp',
    gear= 'gear',
    git= 'git',
    github= 'github',
    gitlab= 'gitlab',
    java= 'java',
    jekyll= 'jekyll',
    julia= 'julia',
    jupyter= 'jupyter',
    kafka= 'kafka',
    kali= 'kali',
    key= 'key',
    kotlin= 'kotlin',
    kubernetes= 'kubernetes',
    matplotlib= 'matplotlib',
    microservices= 'microservices',
    ortools= 'ortools',
    ovh= 'ovh',
    owaps= 'owaps',
    plotly= 'plotly',
    pymc3= 'pymc3',
    python= 'python',
    rasp= 'rasp',
    react= 'react',
    rust= 'rust',
    scaleway= 'scaleway',
    scipy= 'scipy',
    sklearn= 'sklearn',
    spring= 'spring',
    tensorflow= 'tensorflow',
    alephium= 'alephium',
}
