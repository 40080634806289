import {Box, Grid} from "@mui/material";
import {ProjectCard} from "./ProjectCard";
import {Field} from "../domain/field";
import {useContext} from "react";
import {ServiceContext} from "../App";

type TabPanelType = {
    fields: Field[],
}

export function Portofolio({fields}: TabPanelType) {
    const svc = useContext(ServiceContext);

    return <Box>
        <Grid
            container
            direction='row'
            sx={{width: '100%', minHeight: '400px'}}
            justifyContent='start'
            alignItems='stretch'
        >
            {
                svc.project.get(fields).map(project =>
                    <Grid item md={4} xs={12} sx={{padding: 2}}>
                        <ProjectCard project={project}/>
                    </Grid>)
            }


        </Grid>
    </Box>
}
