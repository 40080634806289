import {Field} from "../domain/field";
import {Project} from "../domain/project";
import {Stack} from "../domain/stack";

export class ProjectsService {
    private projects = [
        new Project(
            'usetoll', [Field.CLOUD, Field.WEB3],
            'https://usetoll.com',
            '',
            [Stack.react, Stack.ethereum, Stack.kotlin, Stack.spring]
        ),
        new Project(
            'squ', [Field.CLOUD, Field.DATA],
            '',
            '',
            [Stack.react, Stack.python, Stack.plotly]
        ),
        new Project(
            'geniusheidi', [Field.DATA, Field.CLOUD],
            'https://geniusheidi.ch',
            'https://gitlab.com/helvetica-gpt',
            [Stack.python, Stack.tensorflow, Stack.flutter]
        ),
        new Project(
            'singularty', [Field.WEB3, Field.CLOUD],
            'https://singularty.io',
            'https://gitlab.com/singularty/server',
            [Stack.python, Stack.flask, Stack.react, Stack.ethereum]
        ),
        new Project(
            'alphbet', [Field.WEB3, Field.CLOUD],
            'https://alph.bet',
            'https://github.com/notrustverify/predictalph-frontend',
            [Stack.react, Stack.alephium]
        ),
        new Project(
            'covid', [Field.DATA, Field.CLOUD],
            'https://covid.codable.tv',
            'https://gitlab.com/fjolain/dashboard-covid',
            [Stack.jupyter, Stack.python, Stack.data, Stack.plotly]
        ),
        new Project(
            'copytrading', [Field.CLOUD, Field.DATA],
            'https://copy.trading',
            '',
            [Stack.python],
        ),
        new Project(
            'sta', [Field.CLOUD, Field.PHYSICS],
            'https://www.youtube.com/watch?v=ZK1a4WSXSNc',
            '',
            [Stack.spring, Stack.java, Stack.kafka, Stack.kubernetes]
        ),
        new Project(
            'incognito', [Field.DATA],
            'https://www.youtube.com/watch?v=73mh_mH3FUs',
            'https://gitlab.com/fjolain/incognito',
            [Stack.python, Stack.tensorflow]
        ),
        new Project(
            'vision', [Field.PHYSICS, Field.MOBILE],
            'https://apps.apple.com/fr/app/visions/id6737267943',
            '',
            [Stack.flutter]
        ),
        new Project(
            'adv', [Field.PHYSICS, Field.MOBILE],
            '',
            '',
            [Stack.flutter, Stack.python]
        ),
        new Project(
            'hadar', [Field.PHYSICS, Field.DATA],
            'https://www.hadar-simulator.org',
            'https://github.com/hadar-simulator/hadar',
            [Stack.python, Stack.ortools]
        ),
        new Project(
            'ptg', [Field.PHYSICS, Field.CLOUD],
            '',
            '',
            [Stack.react, Stack.python]
        ),
        new Project(
            'epine', [Field.IOT, Field.MOBILE, Field.CLOUD],
            'https://gitlab.com/epine',
            'https://gitlab.com/epine',
            [Stack.electronic, Stack.fusion360, Stack.python, Stack.rasp, Stack.flutter]
        ),
        new Project(
            'medical', [Field.IOT],
            '',
            '',
            [Stack.flutter, Stack.electronic]
        ),
        new Project(
            'print3d', [Field.IOT],
            '','',
            [Stack.fusion360, Stack.gear]
        ),
        new Project(
            'galaxy', [Field.PHYSICS],
            'https://www.youtube.com/watch?v=aXrmRZPtSsg',
            'https://gitlab.com/fjolain/galaxies_simulater',
            [Stack.python, Stack.scipy]
        ),
        new Project(
            'cavity', [Field.PHYSICS],
            'https://gitlab.com/fjolain/FEM/-/blob/master/rapport_acoustique.pdf?ref_type=heads',
            'https://gitlab.com/fjolain/FEM',
            [Stack.python, Stack.scipy]
        ),
        new Project(
            'luxo', [Field.IOT],
            'https://www.youtube.com/watch?v=iEbiNDQ0BFA',
            'https://gitlab.com/fjolain/luxo',
            [Stack.rasp, Stack.python]
        ),
        new Project(
            'granddebat', [Field.DATA],
            'https://grand-debat.codable.tv/',
            'https://gitlab.com/fjolain/grand-debat',
            [Stack.python, Stack.jupyter, Stack.data, Stack.plotly]
        ),
    ]


    get(fields: Field[]): Project[] {
        return this.projects.filter(p => fields.some(f => p.fields.includes(f)));
    }
}
