import {IconButton, Tooltip, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useState} from "react";

type ProjectCardIconType = {
    disabled: boolean,
    url: string,
    children: JSX.Element
}

export function ProjectCardIcon({ disabled, url, children }: ProjectCardIconType) {
    const [tooltip, setTooltip] = useState(false);
    const { t } = useTranslation();
    const theme = useTheme();

    function open(url: string) {
        window.open(url, 'blank')
    }

    return disabled
        ?  <Tooltip
            disableHoverListener
            title={t('common.ask')}
            open={tooltip}
            onClose={() =>setTooltip(false)}
        >
            <IconButton
                sx={{opacity: theme.palette.action.disabledOpacity}}
                size="small"
                onClick={() => setTooltip(true)}>
                {children}
            </IconButton>
        </Tooltip>
        : <Tooltip title={url}>
            <IconButton
                color='primary'
                size="small"
                onClick={() => open(url)}>
                {children}
            </IconButton>
        </Tooltip>;
}
