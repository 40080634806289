import {Box, Button, Grid, IconButton, Menu, MenuItem, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Mail} from "@mui/icons-material";
import {useContext, useState} from "react";
import {Link} from "react-router-dom";
import {ServiceContext} from "../App";

export const NAVBAR_HEIGHT: number = 70;

type NavbarType = {
    light: boolean
    onThemeChanged: (light: boolean) => void,
}

export function Navbar({light, onThemeChanged}: NavbarType) {

    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const svc = useContext(ServiceContext);

    const [langEl, setLangEl] = useState<HTMLElement | null>(null);
    const [lang, setLang] = useState<string>('en');


    const openLang = (el: HTMLButtonElement) => {
        setLangEl(el);
    };

    const closeLang = () => {
        setLangEl(null);
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setLang(lng);
        closeLang();
    };

    const openModal = () => {
        svc.contacts.open();
    }

    function displayLanguages(): JSX.Element {
        return <Box sx={{textAlign: 'center'}}>
            <Button variant='text'
                    onClick={(event) => openLang(event.currentTarget)}>
                {lang.toUpperCase()}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={langEl}
                open={langEl !== null}
                onClose={closeLang}
            >
                <MenuItem onClick={() => changeLanguage('en')}>EN</MenuItem>
                <MenuItem onClick={() => changeLanguage('fr')}>FR</MenuItem>
                <MenuItem onClick={() => changeLanguage('de')}>DE</MenuItem>
            </Menu>
        </Box>
    }

    function displayStart(): JSX.Element {
        return <Box sx={{textAlign: 'center'}}>
            <IconButton
                sx={{
                    borderRadius: '50%',
                    backgroundColor: theme.palette.secondary.main,
                    color: '#fff',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)', // Shadow effect
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.3)', // Stronger shadow on hover
                    },
                }}
                component={Link}
                to="#start"
                onClick={openModal}
                color='secondary'>
                <Mail/>
            </IconButton>
        </Box>

    }

    return <Box sx={{
        position: 'fixed',
        top: 0, left: 0, zIndex: 999,
        width: '100%',
        height: NAVBAR_HEIGHT,
        background: theme.palette.background.paper
    }}>
        <Grid
            sx={{height: '100%', width: '100%'}}
            container
            direction="row"
            alignItems='center'
        >
            <Grid item xs={2} md={1}></Grid>
            <Grid
                item
                xs={4}
                md={4}
                sx={{
                    justifyContent: 'left',
                }}>
                <Typography variant='h4'>{t('common.title')}</Typography>
            </Grid>

            <Grid item xs={2} md={5}></Grid>

            <Grid
                item
                xs={2}
                md={1}
                sx={{
                    justifyItems: 'center',
                    alignItems: 'center'
                }}>
                {displayLanguages()}
            </Grid>
            <Grid
                item
                xs={2}
                md={1}
                sx={{
                    justifyItems: 'center',
                    alignItems: 'center'
                }}>
                {displayStart()}
            </Grid>

        </Grid>
    </Box>
}
