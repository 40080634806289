import {Box, Typography} from "@mui/material";
import {Paving} from "../components/Paving";
import {FieldsFilter} from "../components/FieldsFilter";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {ServiceContext} from "../App";

export function Home() {
    const {t} = useTranslation();
    const svc = useContext(ServiceContext);

    return <Box>
        <Paving/>

        <Box sx={{marginTop: '40px', marginBottom: '20px', marginLeft: svc.device.unitySize()}}>
            <Typography variant='h4' color='primary'>{t('common.latest').toUpperCase()}</Typography>
        </Box>
        <FieldsFilter/>
    </Box>
}
