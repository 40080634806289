import {Box} from "@mui/material";
import {useEffect, useState} from "react";
import {rand100, randBool} from "../utils";
import {
    Architecture,
    AutoGraph,
    Brush,
    Build,
    Cable,
    Calculate,
    Cloud,
    Code,
    CurrencyBitcoin,
    DataArray,
    DataObject,
    DataThresholding,
    DesignServices,
    Dns,
    Equalizer,
    Handyman,
    Language,
    Laptop,
    Lightbulb,
    Percent,
    Phone,
    PrecisionManufacturing,
    RocketLaunch,
    Settings,
    SquareFoot,
    Usb,
    VpnKey,
    Wifi
} from "@mui/icons-material";

type TileType = {
    icon?: JSX.Element | null,
}

export function Tile({ icon }: TileType) {


    const stacks: JSX.Element[] = [
        <DataArray color='primary'/>,
        <DataObject color='primary'/>,
        <DataThresholding color='primary'/>,
        <Brush color='primary'/>,
        <Build color='primary'/>,
        <Handyman color='primary'/>,
        <Architecture color='primary'/>,
        <SquareFoot color='primary'/>,
        <Code color='primary'/>,
        <Laptop color='primary'/>,
        <Usb color='primary'/>,
        <Phone color='primary'/>,
        <Settings color='primary'/>,
        <AutoGraph color='primary'/>,
        <Cable color='primary'/>,
        <Calculate color='primary'/>,
        <Cloud color='primary'/>,
        <CurrencyBitcoin color='primary'/>,
        <DesignServices color='primary'/>,
        <Dns color='primary'/>,
        <Equalizer color='primary'/>,
        <Language color='primary'/>,
        <Lightbulb color='primary'/>,
        <Percent color='primary'/>,
        <PrecisionManufacturing color='primary'/>,
        <VpnKey color='primary'/>,
        <Wifi color='primary'/>,
        <RocketLaunch color='primary'/>,
    ]
    const [value, setValue] = useState(0);

    function sortValue() {
        // change value randomly 1/4 interval
        if (randBool() && randBool()) {
            return;
        }

        setValue(rand100() % stacks.length);
    }

    useEffect(() => {
        sortValue();
        const interval = setInterval(sortValue, rand100() * 100);
        return () => clearInterval(interval);
    })

    return <Box sx={{
        width: '100%',
        aspectRatio: 1,
        padding: '20%',
        fill: 'primary',
        display: 'flex',
        justifyContent: 'center', // Horizontally center
        alignItems: 'center',    // Vertically center
    }}>
        { icon == null ? stacks[value] : icon }
    </Box>
}
