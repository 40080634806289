export function rand100(): number {
    return Math.floor(Math.random() * 100 + 1);
}

export function randBool(): boolean {
    return rand100() % 2 === 0;
}

export function range(n: number): number[] {
    return Array.from(Array(n).keys());
}
