export class ContactsService {
    callbacks: (() => void)[] = [];

    onOpen(callback: () => void) {
        this.callbacks.push(callback);
    }

    open() {
        this.callbacks.forEach(call => call());
    }

     containsEmail(text: string): boolean {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

        return emailRegex.test(text);
    }

    async sendForm(message: string): Promise<boolean> {
        if (!this.containsEmail(message)) {
            return false;
        }

        const res = await fetch(
            "https://jolaincontactsdsm6qxze-contacts-form.functions.fnc.fr-par.scw.cloud",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", // Specify JSON format
                },
                body: JSON.stringify({ message }), // Convert JavaScript object to JSON string
            }
        );

        return res.status === 200;
    }
}
