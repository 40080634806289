import {useState} from "react";
import {Box, FormControlLabel, Grid, Switch} from "@mui/material";
import {Portofolio} from "./Portofolio";
import {useTranslation} from "react-i18next";
import {Field} from "../domain/field";
import {Tune} from "@mui/icons-material";

export function FieldsFilter() {
    const fields: Field[] = Object.values(Field);
    const [selected, setSelected] = useState<Field[]>(fields);
    const {t} = useTranslation();

    function change(field: Field): void {
        if (selected.includes(field)) {
            setSelected(selected.filter(f => f !== field));
        } else {
            setSelected(selected.concat(field));
        }
    }

    function all(): void {
        if (isAll()) {
            setSelected([]);
        } else {
            setSelected(fields)
        }
    }

    function isAll(): boolean {
        return fields.every(f => selected.includes(f));
    }

    function displayField(field: Field): JSX.Element {
        return <FormControlLabel
            label={t(`field.${field}.title`)}
            control={
                <Switch
                    onClick={() => change(field)}
                    checked={selected.includes(field)}
                />}/>
    }

    function displayAll(): JSX.Element {
        return <FormControlLabel
            label={t(`common.all`)}
            control={
                <Switch
                    color='secondary'
                    onClick={() => all()}
                    checked={isAll()}
                />}
        />;
    }

    return <>
        <Grid
            container
            direction='row'
        >
            <Grid item md={1} xs={2} sx={{
                fill: 'primary',
                display: 'flex',
                justifyContent: 'center', // Horizontally center
                alignItems: 'center',    // Vertically center
            }}>
                <Tune/>
            </Grid>
            <Grid item md={11} xs={10}>
                {displayAll()}
                {fields.map(field => displayField(field))}
            </Grid>

        </Grid>
        <Box sx={{height: '40px'}}/>
        <Portofolio fields={selected}/>
    </>;
}
