import {Box, Button, Card, Grid, Modal, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RocketLaunch} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {ServiceContext} from "../App";
import {ClipboardText} from "./ClipboardText";


export function ModalContacts() {
    const {t} = useTranslation();
    const svc = useContext(ServiceContext);

    const [description, setDescription] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);

    useEffect(() => {
        svc.contacts.onOpen(() => setOpen(true));
    })

    async function sendForm(): Promise<void> {
        if (description === '') {
            return;
        }

        const res = await svc.contacts.sendForm(description);
        setError(!res);

        if (res) {
            setSent(true);
        }
    }

    return <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{padding: '20px'}}>
        <Card sx={{
            width: {xs: "80%", md: "500px"}, // 80% on mobile, 500px on desktop

            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px'
        }}>
            <Typography variant='h6' style={{marginBottom: '10px'}}>
                {t('contact.byMail')}
            </Typography>
                <Grid>
                    <ClipboardText text={'jolain@jolain.net'}/>
                    <ClipboardText text={'+41 77 260 18 47'}/>
                </Grid>

            <Typography variant='h6' style={{textAlign: 'center', marginBottom: '10px'}}>
                {t('contact.byForm')}
            </Typography>
            <TextField
                fullWidth
                sx={{
                    marginTop: '10px',
                    '& .MuiInput-underline:before': {
                        borderBottom: 'none', // Remove the default bottom border
                    },
                    '& .MuiInput-underline:after': {
                        borderBottom: 'none', // Remove the focused bottom border
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottom: 'none', // Remove the hover bottom border
                    },
                }}
                id="standard-basic"
                label={t('contact.name')}
                multiline
                rows={6}
                variant="outlined"
                value={description}
                helperText={error &&
                    <Typography variant='caption' color='error'>Please write us your email</Typography>}
                onChange={event => setDescription(event.target.value)}
                InputProps={{
                    sx: {color: 'text.secondary'},
                }}
            />
            <Box sx={{height: '30px'}}/>

            {sent
                ? <Button
                    variant='outlined'
                    color='secondary'
                    startIcon={<RocketLaunch/>}
                >
                    {t('contact.thanks')}
                </Button>
                : <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<RocketLaunch/>}
                    onClick={() => sendForm().then()}
                >
                    {t('contact.toProduct')}
                </Button>
            }
        </Card>
    </Modal>
}
