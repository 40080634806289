import {useTranslation} from "react-i18next";
import {Grid, Typography, useTheme} from "@mui/material";
import {NAVBAR_HEIGHT} from "./Navbar";

export function Footer() {
    const { t } = useTranslation();
    const theme = useTheme();

    return <Grid
            container
            direction='row'
            justifyContent='space-around'
            alignItems='center'
            sx={{
                height: NAVBAR_HEIGHT,
                background: theme.palette.primary.main,
                color: theme.palette.background.paper,
                textAlign: 'center'
    }}
    >
        <Grid item md={3}>
            <Typography>{t('footer.location')} 🇨🇭</Typography>
        </Grid>
        <Grid item md={3}>
            <Typography>{t('footer.legal')}</Typography>
        </Grid>
        <Grid item md={3}>
            <Typography>{t('footer.copyright')}</Typography>
        </Grid>

    </Grid>
}
