import {Field} from "./field";
import {Stack} from "./stack";

export class Project {
    constructor(
        public id: string,
        public fields: Field[],
        public link: string,
        public code: string,
        public stacks: Stack[]
    ) {
    }

    get isPrivate(): boolean {
        return this.link === '';
    }

    get isCloseSource(): boolean {
        return this.code === '';
    }

    get image(): string {
        return `/img/project/${this.id}.jpeg`
    };
}
